import { GEO_IP_RESTRICTIONS_ENABLED } from '@shared/utils/constant'

export const restrictedMitoCountries = ['CA', 'GB']

export const isCountryFullyRestricted = (country: string) => {
  const jsonStore = useSharedJsonStore()

  if (!GEO_IP_RESTRICTIONS_ENABLED) {
    return false
  }

  return jsonStore.restrictedCountries.includes(country)
}

export const isCountryRestricted = (country: string) => {
  const jsonStore = useSharedJsonStore()

  if (!GEO_IP_RESTRICTIONS_ENABLED) {
    return false
  }

  return [
    ...restrictedMitoCountries,
    ...jsonStore.restrictedCountries
  ].includes(country)
}
